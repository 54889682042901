import { computed, reactive } from 'vue'
import useVuelidate from "@vuelidate/core";
import {helpers, email, required, minLength, maxLength } from "@vuelidate/validators";
import moment from "moment";

import { dynamicFormStore } from "@mints-components/cali-dynamic-form/stores/store";


export const required$ = helpers.withMessage('Este campo no puede estar vacío', required)

export const email$ = helpers.withMessage('El valor no tiene formato de correo (ejemplo@mail.com)', email)

export function validName(name) {
    let validNamePattern = new RegExp("^[A-zÀ-ú]+(?:[-'.\\s][A-zÀ-ú]+)*[\\s]*$");
    if (validNamePattern.test(name)) {
        return true;
    }
}

export function checkBoxValidation(bool) { return bool === true }

export function confirmPassword(pass) {
    return dynamicFormStore().password === pass
}

export function legalAge(date) {
    const givenDate = moment(date)
    const currentDate = moment();
    const eighteenYearsAgo = currentDate.subtract(18, 'years');
    return givenDate.isBefore(eighteenYearsAgo);
}

export function validPhone(numberObject) {
    return numberObject.number.length === 10
}

export function validPassword(password) {
    const passwordPattern = /^(?=.*[a-zA-Z0-9!@#$%^&*()_+,\-.;?]).{6,16}$/;
    return passwordPattern.test(password);
}

export function attachValidations(field) {
    let validation = {}

    if (field.required) {
        validation = {...validation, required$ }
    }

    if ((field.validations || []).includes('email')) {
        validation = {...validation, email$ }
    }

    if ((field.validations || []).includes('max:')) {
        let lengthMax = field.validations.find(x => x.includes('max:')).split(':')
        validation = {...validation, ...maxLength(lengthMax[lengthMax.length - 1]) }
    }

    if ((field.validations || []).includes('min:')) {
        let lengthMin = field.validations.find(x => x.includes('min:')).split(':')
        validation = {...validation, ...minLength(lengthMin[lengthMin.length - 1]) }
    }

    if ((field.validations || []).includes('password')) {
        validation = {...validation,
            ... {
                password: {
                    $validator: validPassword,
                    $message: 'La contraseña debe contener entre 6 y 16 caracteres'
                }
            }
        }
    }

    if ((field.validations || []).includes('confirmation')) {
        validation = {...validation,
            ... {
                confirmation: {
                    $validator: confirmPassword,
                    $message: 'Las contraseñas no coinciden'
                }
            }
        }
    }


    if ((field.validations || []).includes('phone')) {
        validation = {...validation,
            ... {
                phone_valid: {
                    $validator: validPhone,
                    $message: 'Escribe tu número de teléfono (10 dígitos)'
                }
            }
        }
    }

    if ((field.validations || []).includes('terms')) {
        validation = {...validation,
            ... {
                checkbox: {
                    $validator: checkBoxValidation,
                    $message: 'Debes aceptar los términos y condiciones para registrarte'
                }
            }
        }
    }

    if ((field.validations || []).includes('date')) {
        validation = {...validation,
            ... {
                legal_age: {
                    $validator: legalAge,
                    $message: 'Debe ser mayor de edad para poder participar'
                }
            }
        }
    }

    if (field.validName) {
        validation = {...validation,
            ... {
                name_validation: {
                    $validator: validName,
                    $message: 'Los nombres validos sólo contienen letras, guiones y espacios'
                }
            }
        }

    }

    return validation
}

export const generateValidatorInstance = (form) => {
    const fields = form && form.config_json ? form.config_json.fields : [];

    let validations = {}
    let formState = {}
    fields.forEach(field => {
        validations[field.slug] = attachValidations(field)
        formState[field.slug] = null
    });

    const rules = computed(() => ({
        formFilled: validations,
    }))

    const state = reactive({
        formFilled: formState
    })

    return useVuelidate(rules, state);
};