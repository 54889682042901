import { defineStore } from 'pinia'

export const dynamicFormStore = defineStore('dynamicForm', {
  state: () => ({
    form: {}, answers: {},
    step: 1, status: {},
    password: null,
    productLine: null,
    products: []
  }),
  actions: {
    updatePassword(pass) {
      this.password = pass
    },
    updateAnswer({ key, value }) {
      if (key === 'password') this.password = value
      this.answers[key] = value
    },
    setFormData(payload) {
      this.form = payload
    },
    nextStep() {
      this.step ++;
    },
    resetStep() {
      this.step = 1
    },
    changeStatus({ key, value }) {
      this.status[key] = value
    },
    resetAnswers() {
      this.answers = {}
    },
    verifyPassword() {
      for (const answer in this.answers) {
        // console.log( typeof answer)
      }
    },
    verifyIfKeysHasValue() {
      return this.currentInputs.every(field => Boolean(this.answers[field.slug]))
    },
    updateProductLine(newValue) {
      this.productLine = newValue
    },
    productList(productLine) {
      let listaFiltrada = []

      // switch (productLine) {
      //   case 'ACONDICIONADOR DE MANZANILLA':
      //     listaFiltrada = ['ACONDICIONADOR DE MANZANILLA-ACONDICIONADOR DE MANZANILLA']
      //     break;
      //   case 'BODY WASH':
      //     listaFiltrada = [
      //       'BODY WASH-JABÓN LÍQUIDO LAVANDA Y LECHUGA',
      //       'BODY WASH-JABÓN LÍQUIDO AVENA & VAINILLA',
      //       'BODY WASH-JABÓN LÍQUIDO MANZANILLA Y MIEL'
      //     ]
      //     break;
      //   case 'CORPORALES':
      //     listaFiltrada = [
      //       'CORPORALES-ACEITE CON ATOMIZADOR LAVANDA',
      //       'CORPORALES-AGUA DE COLONIA DULCES SUEÑOS',
      //       'CORPORALES-CREMA DE AVENA & VAINILLA',
      //       'CORPORALES-CREMA DE MIEL',
      //       'CORPORALES-SPRAY ANTI MOSCOS',
      //       'CORPORALES-AGUA DE COLONIA MANZANILLA',
      //       'CORPORALES-CREMA LAVANDA',
      //       'CORPORALES-LOCIÓN',
      //       'CORPORALES-RICITOS DE ORO BLOQUEADOR',
      //       'CORPORALES-POST MOSQUITO'
      //     ]
      //     break;
      //   case 'JABONES':
      //     listaFiltrada = [
      //       'JABONES-3PACK JABONES MIXTOS',
      //       'JABONES-AVENA',
      //       'JABONES-LAVANDA',
      //       'JABONES-MANZANILLA',
      //       'JABONES-MIEL',
      //       'JABONES-NEUTRO',
      //       'JABONES-BIO PURE'
      //     ]
      //     break;
      //   case 'KIT RICITOS DE ORO':
      //     listaFiltrada = [
      //       'KIT RICITOS DE ORO-MINI KIT DE LAVANDA 4PZS',
      //       'KIT RICITOS DE ORO-MINI KIT DE VIAJE MANZANILLA 4 PZAS'
      //     ]
      //     break;
      //   case 'LÍNEA PEDIÁTRICA':
      //     listaFiltrada = [
      //       'LÍNEA PEDIÁTRICA-RICITOS DE ORO MANZANILLA LUB',
      //       'LÍNEA PEDIÁTRICA-PASTA DE LASSAR'
      //     ]
      //     break;
      //   case 'PRODUCTOS PARA PEINADO':
      //     listaFiltrada = [
      //       'PRODUCTOS PARA PEINADO-GEL MANZANILLA',
      //       'PRODUCTOS PARA PEINADO-GEL MIEL',
      //       'PRODUCTOS PARA PEINADO-LOCIÓN ACLARANTE',
      //       'PRODUCTOS PARA PEINADO-SPRAY DESENREDANTE MIEL ARGÁN',
      //       'PRODUCTOS PARA PEINADO-GEL LÍQUIDO',
      //       'PRODUCTOS PARA PEINADO-GEL'
      //     ]
      //     break;
      //   case 'SHAMPOO':
      //     listaFiltrada = [
      //       'SHAMPOO-LAVANDA',
      //       'SHAMPOO-MANZANILLA',
      //       'SHAMPOO-MIEL',
      //       'SHAMPOO-NEUTRO',
      //       'SHAMPOO-BIO PURE'
      //     ]
      //     break;
      //   default:
      //     listaFiltrada = []
      //     break;
      // }
      this.products = listaFiltrada
      return this.products
    },
  },
  getters: {
    // Global getters
    formType: (state) => state.form?.config_json?.is_progressive_form ? 'Progressive' : 'Static',
    isProgressive() { return this.formType === 'Progressive' },
    title: (state) => state.form?.config_json?.form_title,
    description: (state) => state.form?.description,
    fields: (state) => state.form?.config_json?.fields,
    inputFields() { return this.fields?.filter(f => f.data_type_enum !== 16) },
    labelFields() { return this.fields?.filter(f => f.data_type_enum === 16) || [] },
    buttonField() { return this.labelFields.find(f => f?.extra_parameters?.is_footer_button) },
    defaultApi: (state) => state.form?.slug ? '/api/v1/forms/' + state.form.slug : '',
    submitStatus: (state) => state.status?.submit,
    // Progressive form getters
    maxStep() {
      return this.inputFields?.reduce((carry, field) => {
        return field?.extra_parameters?.step > carry ? field.extra_parameters.step : carry
      }, 1)
    },
    currentInputs(state) { return this.inputFields.filter(field => field.extra_parameters?.step === state.step) },
    currentLabels(state) { return this.labelFields.filter(field => field.extra_parameters?.step === state.step) },
    disabledProductSelection(state) { return state.products.length === 0},
    filteredProducts(state) { return this.productList(state.productLine)}
  }
});
